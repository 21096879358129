import React from 'react'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import PropertyFAQs from '@rentivo/gatsby-core/src/components/property/PropertyFAQs/component'
import { withProperty } from '@rentivo/gatsby-core/src/context/PropertyContext'
import {
  selectPropertyLocale,
  selectPropertyTextByTypeByLocaleFromTexts,
  selectPropertyTexts,
} from '@rentivo/gatsby-core/src/selectors/property'
import {
  WHAT_IS_INCLUDED,
  WHAT_IS_NOT_INCLUDED,
  FAQ,
} from '@rentivo/gatsby-core/src/constants/lycanConstants'

const defaultProps = {
  includedTitle: null,
  notIncludedTitle: null,
  showTitles: true,
  lines: 10,
  wrapper: ({ children }) => children,
}

const propertySelector = (state, { type }) =>
  createSelector(selectPropertyTexts, selectPropertyLocale, (texts, locale) => {
    type = type ? type : defaultProps.type

    locale = locale && locale.includes('_') ? locale.split('_')[0] : locale

    return {
      faqs: selectPropertyTextByTypeByLocaleFromTexts(texts, FAQ, locale),
    }
  })(state)

const enhance = compose(withProperty(propertySelector))

PropertyFAQs.defaultProps = defaultProps

export default enhance(PropertyFAQs)
