import { defineMessages } from 'react-intl'

export const scope = 'components.property.PropertyFAQs'

export default defineMessages({
  faqTitle: {
    id: `${scope}.faq.title`,
    defaultMessage: 'FAQs',
  },
})
