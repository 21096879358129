import React from 'react';
import { FormattedNumber } from 'react-intl';
import { Text } from '@chakra-ui/react';

const Price = ({
  price,
  currency,
  currentCurrency,
  getExchangedCurrency,
  defaultValue,
  toPrice,
  fractionDigits,
  prefix,
  periodSeparator,
  periodSuffix,
  priceSeparator,
  showZero
}) => {
  const defaultComp = (defaultValue === undefined) ? null : <Text as="span" fontWeight="semibold" className="price">{defaultValue}</Text>;
  if (!currency || price === null || price === undefined || (price === 0 && showZero === false)) return defaultComp;
  /*const exchangedPrice = getExchangedCurrency(price, fractionDigits, currency);
  if(exchangedPrice === 0 || exchangedPrice === "0") {
    console.log(currency + ' does not exist');
    return defaultComp;
  }*/

  return (
    <span className="price">
        <span className="visual">
          {prefix && <Text as="span" mr={2} className="price-prefix" color="textLight" size="sm">{prefix}</Text>}
          <Text as="span" fontWeight="semibold" className="price-number">
            <FormattedNumber
              value={price}
              useGrouping={true}
              style="currency"
              currencyDisplay="symbol"
              currency={currency}
              minimumFractionDigits={fractionDigits}
              maximumFractionDigits={fractionDigits}
            />
            {toPrice && (
              <span>
                <span>{priceSeparator}</span>
                <FormattedNumber
                  value={toPrice}
                  useGrouping={true}
                  style="currency"
                  currencyDisplay="symbol"
                  currency={currency}
                  minimumFractionDigits={fractionDigits}
                  maximumFractionDigits={fractionDigits}
                />
              </span>
            )}
          </Text>
          {periodSuffix && (<Text className="period" as="span" color="textLight" size="sm">{periodSeparator !== '' && (" ")}{periodSeparator} {periodSuffix}</Text>)}
        </span>
    </span>
  );

};


export default Price;
