import React from 'react'
import { StyledPropertyFAQs } from '@rentivo/gatsby-core/src/components/property/PropertyFAQs/styles'
import { FormattedMessage } from 'react-intl'
import messages from '@rentivo/gatsby-core/src/components/property/PropertyFAQs/messages'
import TruncateText from '@rentivo/gatsby-core/src/components/ui/TruncateText'
import { Heading } from '@chakra-ui/react'

const PropertyFAQsComponent = ({
  wrapper: Wrapper,
  faqs,
  showTitles,
  lines,
  includedTitle,
  notIncludedTitle,
  className,
}) => {
  if (!faqs) return null

  return (
    <Wrapper>
      <StyledPropertyFAQs className={className}>
        {showTitles && (
          <Heading as="h2" mb={3}>
            {includedTitle ? (
              includedTitle
            ) : (
              <FormattedMessage {...messages.faqTitle} />
            )}
          </Heading>
        )}
        <TruncateText lines={lines} text={faqs} />
      </StyledPropertyFAQs>
    </Wrapper>
  )
}

export default PropertyFAQsComponent
