import React, { useState } from 'react';
import { Tooltip, Box } from '@chakra-ui/react';

const TouchTooltip = ({children, render, wrapperStyleProps = {}, ...rest}) => {
  const [isLabelOpen, setIsLabelOpen] = useState(false);

  const mouseProps = {
    onMouseEnter: () =>  setIsLabelOpen(true),
    onMouseLeave: () => setIsLabelOpen(false),
    onClick: () => setIsLabelOpen(true)
  };

  return (
    <Tooltip
      {...rest}
      isOpen={isLabelOpen}
    >
      <Box
        as="span"
        {...wrapperStyleProps}
        {...mouseProps}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default TouchTooltip;
