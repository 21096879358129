import React from 'react';
import Info from '@rentivo/gatsby-core/src/components/ui/Info/component';

Info.defaultProps = {
  title:        '',
  placement:    'bottom',
  className:    '',
  tooltip:      {}
};

export default Info
