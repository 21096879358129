import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import Price from '@rentivo/gatsby-core/src/components/generic/Price/component';
import {
  makeSelectCurrencyExchangeMap,
  makeSelectCurrentCurrency,
  makeSelectGetExchangedCurrency
} from '@rentivo/gatsby-core/src/containers/CurrencyProvider/selectors';

const mapStateToProps = createSelector(
  makeSelectCurrentCurrency(),
  makeSelectCurrencyExchangeMap(),
  makeSelectGetExchangedCurrency,
  (currentCurrency, currencyExchangeMap, getExchangedCurrency) => ({
    currentCurrency,
    currencyExchangeMap,
    getExchangedCurrency
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

Price.defaultProps = {
  toPrice: null,
  fractionDigits: 0,
  prefix: '',
  periodSuffix: '',
  periodSeparator: '/',
  priceSeparator: '—',
  defaultValue: null,
  showZero: false
};

export default enhance(Price);
