import { createSelector } from 'reselect';
import {
  selectThemeOptionFromOptions,
  selectThemeOptions
} from '@rentivo/gatsby-core/src/theme/selectors';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropertyLayout from '@rentivo/gatsby-theme-barcelona/src/templates/PropertyPublic/components/PropertyLayout/component';
import { withTheme } from '@emotion/react';

const mapStateToProps = createSelector(
  selectThemeOptions, (themeOptions) => ({
    footerHeight: selectThemeOptionFromOptions(themeOptions, 'footerHeight', 0)
  })
);

const enhance = compose(
  withTheme,
  connect(mapStateToProps)
);

export default enhance(PropertyLayout);
