import React, { useContext } from 'react';

export const CommerceContext = React.createContext({});

export const withCommerce = (selector = null) => WrappedComponent => {
  const WithCommerceComponent = (props) => {
    const { ...rest } = props;
    const commerce = useContext(CommerceContext);

    const selectorCommerce = (selector) ? selector({...commerce}, props) : {};
    if(!selector) rest.commerce = commerce; // Add whole commerce if nothing is specified.

    return <WrappedComponent {...rest} {...selectorCommerce}/>;
  };

  return WithCommerceComponent;
};

export const CommerceProvider = ({children, ...rest}) => {
  const { commerce } = rest;
  return (
    <CommerceContext.Provider value={commerce}>
      {children}
    </CommerceContext.Provider>
  )
};

export const CommerceComponent = ({component: Component, ...rest}) => {
  const { commerce } = rest;
  return (
    <CommerceContext.Provider value={commerce}>
      <Component {...rest} commerce={commerce}/>
    </CommerceContext.Provider>
  )
};
