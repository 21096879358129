/*
import React from 'react';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import {
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';


const InfoComponent = ({title, placement, className, tooltip, ...rest}) => {
  if(!title) return null;
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <AwesomeIcon icon={faInfoCircle}/>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody>
          <Text as="span">{title}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
};

export default InfoComponent;
 */


import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { Text, Box } from '@chakra-ui/react';
import TouchTooltip from '@rentivo/gatsby-core/src/components/ui/TouchTooltip';
import AwesomeIcon from '@rentivo/gatsby-core/src/components/ui/AwesomeIcon';

const InfoComponent = ({title, placement, className, tooltip, children, ...rest}) => {
  if(!title) return null;
  return (
    <Text as="span" className={className} {...rest}>
      <TouchTooltip {...tooltip} label={title}>{children}<AwesomeIcon icon={faInfoCircle} ml={children ? 2 : 0}/></TouchTooltip>
    </Text>
  )
};

export default InfoComponent;
