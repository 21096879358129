import React from 'react';
import PropertyComponent from '@rentivo/gatsby-theme-barcelona/src/templates/PropertyPublic/component';
import { useMenusAndBlocksData } from '@rentivo/gatsby-theme-barcelona/src/hooks/usePageData';

const Property = (props) => {
  const menusAndBlocks = useMenusAndBlocksData(props);
  return <PropertyComponent {...menusAndBlocks}/>;
};

export default Property;
